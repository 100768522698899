const baseURL = "https://futurefish-api.onrender.com";

// const baseURL = "http://localhost:8080";
export const Endpoints = {
  UploadFile: `${baseURL}/file/upload`,
  Login: `${baseURL}/member/login`,
  GetProfile: `${baseURL}/member/profile`,

  GetTeam: `${baseURL}/members/all`,
  AddTeamMember: `${baseURL}/member/add`,
  UpdateMemberProfile: `${baseURL}/member/update`,
  DeleteTeamMember: `${baseURL}/member/delete`,

  GetNewsletterRecipients: `${baseURL}/newsletter/get-recipients`,
  RemoveFromNewsletter: `${baseURL}/newsletter/eject`,

  GetOrders: `${baseURL}/orders/get`,
  UpdateOrderStatus: `${baseURL}/order/update`,

  GetStats: `${baseURL}/stats/get`,
  UpdateStat: `${baseURL}/stat/update`,

  GetQuestions: `${baseURL}/questions/get`,
  AddQuestion: `${baseURL}/question/add`,
  UpdateQuestion: `${baseURL}/question/update`,
  DeleteQuestion: `${baseURL}/question/delete`,

  CreatePost: `${baseURL}/blog/create`,
  UpdatePost: `${baseURL}/blog/update`,
  ChangePostStatus: `${baseURL}/blog/change-status`,
  SetFeaturedPost: `${baseURL}/blog/set-featured`,
  GetFeaturedPost: `${baseURL}/blog/get-featured`,
  GetBlogPosts: `${baseURL}/blogs/get`,
  GetSinglePost: `${baseURL}/blog/get`,
  DeletePost: `${baseURL}/blog/delete`,

  RequestOTP: `${baseURL}/member/password/reset`,
  UpdatePassword: `${baseURL}/member/password/update`,
};
